import CartItemPrice from './CartItemPrice';
import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import { formatMoney } from '@shopify/theme-currency';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartItem = ({ item }) => {
  const [error, setError] = useState();

  return (
    <div class="relative flex border-b border-grey-3 py-5 md:py-7">
      {item.featured_image && item.featured_image.url && (
        <div class="mr-3 w-20">
          <a class="block aspect-product-item" href={item.url}>
            <img
              class="h-full w-full object-cover"
              alt={item.featured_image.alt}
              src={getSizedImageUrl(item.featured_image.url, 'medium')}
            />
          </a>
        </div>
      )}

      <div class="flex-1">
        <div class="md:flex md:items-start">
          <div class="flex-1">
            <a class="mb-2 block" href={item.url}>
              {item.title}
            </a>
            <div class="mt-2 flex flex-wrap">
              <span class="type-text-8 mb-1 basis-full md:mb-0 md:flex-grow-0 md:basis-0">
                {item.price > item.final_price && (
                  <span class="mr-2 line-through">
                    {formatMoney(item.price, item.currency)}
                  </span>
                )}
                <span>{formatMoney(item.final_price, theme.moneyFormat)}</span>
              </span>
              {!item.product_has_only_default_variant &&
                item.options_with_values.map(({ value }) => (
                  <span class="type-text-8 before:mx-2 before:content-['·']">
                    {value}
                  </span>
                ))}
            </div>
            {item.selling_plan_allocation && (
              <span class="type-text-8 my-1 inline-flex items-center bg-grey-4 px-2 py-1">
                <svg
                  className="mr-1 w-5"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3 3.3v4.2h.5m0 0a6.7 6.7 0 0 1 12.8 1.7M3.8 7.5h3.7m9.2 9.2v-4.2h-.5m0 0a6.7 6.7 0 0 1-12.8-1.7m12.8 1.7h-3.7"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
                {item.selling_plan_allocation.selling_plan.name}
              </span>
            )}
          </div>

          {item.properties &&
            Object.entries(item.properties).map(
              ([key, val]) =>
                val &&
                !key.startsWith('_') && (
                  <p>
                    {key}:{' '}
                    {val.includes('/uploads/') ? (
                      <a href={val}>{val.split('/').pop()}</a>
                    ) : (
                      val
                    )}
                  </p>
                ),
            )}

          <div class="md:flex md:flex-row-reverse md:items-center">
            <div class="mb-2 ml-4 md:mb-0 md:ml-10">
              <CartItemPrice item={item} />
            </div>
            <QuantitySelector item={item} setError={setError} />
          </div>
        </div>
        <CartRemove item={item} />

        {/* Discounts */}
        {item.line_level_discount_allocations.length > 0 && (
          <div class="mt-2 space-y-2">
            {Object.entries(item.line_level_discount_allocations).map(
              ([key, value]) => (
                <div key={key} class="type-text-8">
                  {value.discount_application.title}
                </div>
              ),
            )}
          </div>
        )}
      </div>

      {error && (
        <div class="type-text-8 absolute bottom-1 right-0 text-error">
          {getTranslation('cart.quantity_error', { quantity: error })}
        </div>
      )}
    </div>
  );
};

export default CartItem;
