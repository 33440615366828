import * as cartApi from '../../helpers/cartApi';

import AsyncView from '../../helpers/AsyncView';
import JSON5 from 'json5';
import { updateReactCart } from '../helpers/utilities';
import { createContext, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

// Change this variable to add compare at pricing to the cart data.
// Adds load time
const ADD_COMPARE_AT_PRICING = true;
const OPEN_PARAM = 'openMiniCart';

const preactApp = document.querySelector('#pxu-preact-app');

const CartContext = createContext();

const url = new URL(window.location.href);
let cartOpen = false;
if (url.searchParams.has(OPEN_PARAM)) {
  cartOpen = true;
}

const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState();
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [miniCartData, setMiniCartData] = useState({});

  useEffect(() => {
    getCart();
    setupMiniCart();

    if (cartOpen) {
      // Don't get the transition if you open too fast. Don't love this.
      setTimeout(() => {
        toggleMiniCart(true);
        removeOpenParam();
      }, 2000);
    }

    const func = e => {
      getCart(e.detail.openMiniCart);
    };

    preactApp.addEventListener('updateReactCart', func);

    return () => {
      preactApp.removeEventListener('updateReactCart', func);
    };
  }, []);

  const getCart = openMiniCart => {
    cartApi.get().then(response => {
      updateCart(response, openMiniCart);
    });
  };

  const removeOpenParam = () => {
    const paramExp = new RegExp(`[\?&]${OPEN_PARAM}=+`);
    history.replaceState(
      null,
      '',
      location.pathname +
        location.search.replace(paramExp, '').replace(/^&/, '?') +
        location.hash,
    );
  };

  const updateCart = (newCart, openMiniCart) => {
    if (ADD_COMPARE_AT_PRICING) {
      AsyncView.load(window.Shopify.routes.root + 'cart', {
        view: 'compare-price',
      })
        .then(({ data }) => {
          const mutatedItems = newCart.items.map(item => {
            if (data.cart[`id-${item.variant_id}`]) {
              item.compare_at_price =
                data.cart[`id-${item.variant_id}`].compare_at_price;
            } else {
              item.compare_at_price = item.price;
            }
            return item;
          });
          setCart({ ...newCart, items: mutatedItems });
          if (openMiniCart) {
            toggleMiniCart();
          }
        })
        .catch(() => {
          setCart(newCart);
        });
    } else {
      setCart(newCart);
      if (openMiniCart) {
        toggleMiniCart();
      }
    }
  };

  const addLineItem = async data => {
    return await cartApi
      .add(data)
      .then(() => {
        updateReactCart(true);
        document.querySelector('[data-cart-items]').scrollTop = 0;
      })
      .catch(response => _handleCartApiError(response));
  };

  const updateLineItem = async (itemKey, quantity) => {
    const updatedCart = await cartApi.change(itemKey.toString(), quantity);
    const newItem = updatedCart.items.find(item => item.key === itemKey);

    updateCart(updatedCart);
    return newItem;
  };

  const removeLineItem = async itemKey => {
    const updatedCart = await cartApi.remove(itemKey.toString());

    updateCart(updatedCart);
    return true;
  };

  /**
   * Generic error handler for cart api
   * Example: 422 response when trying to add a product whose total stock is already in cart
   *
   * @param {Object} response The response from Shopify
   */
  const _handleCartApiError = response => {
    const error = response.description;
    alert(error);
  };

  /**
   * Toggle MiniCart visibility
   *
   * @param state - forces a visible (true) or hidden (false) state
   */
  const toggleMiniCart = state => {
    let newMiniCartState = false;

    if (state !== undefined) {
      newMiniCartState = state;
    } else {
      newMiniCartState = !isMiniCartOpen;
    }

    if (window.location.pathname.startsWith('/cart')) {
      setIsMiniCartOpen(false);
    } else {
      setIsMiniCartOpen(newMiniCartState);
    }
    history.replaceState(
      null,
      '',
      location.pathname +
        location.search.replace(/[\?&]openMiniCart=+/, '').replace(/^&/, '?') +
        location.hash,
    );
  };

  /**
   * Retrieve reuseable data from DOM
   *
   */
  const setupMiniCart = () => {
    const dataEl = document.querySelector(
      '[data-section-type="main-minicart"]',
    );
    const data = dataEl.getAttribute('data-section-data') || dataEl.innerHTML;
    const cartData = JSON5.parse(data);

    setMiniCartData(cartData);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addLineItem,
        updateLineItem,
        removeLineItem,
        isMiniCartOpen,
        toggleMiniCart,
        miniCartData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCartContext() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartContextProvider');
  }
  return context;
}

export { CartContextProvider, useCartContext };
