import Accordion from '../components/Accordion';

export default class DynamicFaq {
  constructor(section) {
    this._initAccordion(section);
  }

  _initAccordion(section) {
    const el = section.el.querySelector('[data-faq-block]');

    if (!el) {
      return;
    }

    new Accordion({
      el: el,
      triggerClass: 'faq-trigger',
      contentClass: 'faq-content',
      closeOnClickOutside: false,
    });
  }
}
