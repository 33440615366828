import debounce from './debounce';

/**
 * Scroll direction detection
 */
class Scroll {
  /**
   * @param {Function} onPageScroll - callback function for scroll direction
   * @returns {String} the direction of scroll ("up" or "down")
   */
  constructor(onPageScroll, rootEl) {
    this.onPageScroll = onPageScroll;
    this.lastScrollTop = window.pageYOffset;
    this.headerHeight = rootEl.clientHeight;

    document.addEventListener('scroll', this.onScroll);
  }

  onScroll = debounce(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (
      scrollTop < this.headerHeight ||
      scrollTop < this.lastScrollTop ||
      this.lastScrollTop === -1
    ) {
      this.onPageScroll('up');
    } else {
      this.onPageScroll('down');
    }

    this.lastScrollTop = scrollTop;
  });
}

export default Scroll;
