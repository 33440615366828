/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './layout/**/*.liquid',
    './sections/**/*.liquid',
    './snippets/**/*.liquid',
    './templates/**/*.liquid',
    './src/scripts/**/*.{js,jsx}',
    './assets/**/*.js',
  ],
  safelist: [
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8',
    'md:col-span-9',
    'md:col-span-10',
    'md:col-span-11',
    'md:col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    colors: {
      primary: {
        1: '#FDF7F1',
        2: '#D1BEAD',
        3: '#836850',
      },
      secondary: {
        1: '#190721',
        2: '#212F45',
        3: '#9AC6C4',
      },
      tertiary: {
        1: '#A7A551',
        2: '#777831',
      },
      grey: {
        1: '#292929',
        2: '#757575',
        3: '#959595',
        4: '#f8f8f8',
      },
      transparent: 'transparent',
      black: 'black',
      white: 'white',
      current: 'currentColor',
      success: '#4cc258',
      warning: '#f48e2f',
      error: '#f4522f',
    },
    fontFamily: {
      sans: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      heading:
        '"The Seasons", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    screens: {
      xs: '460px',
      sm: '576px',
      md: '820px',
      lg: '1024px',
      xl: '1366px',
    },
    extend: {
      borderRadius: {
        huge: '3.75rem',
      },
      aspectRatio: {
        'product-item': '2 / 3',
        fullscreen: '4 / 3',
        'vertical-video': '9 / 16',
      },
      spacing: {
        15: '3.75rem',
      },
      gridTemplateColumns: {
        'flexible-6': 'repeat(6, 1fr)',
        'flexible-12': 'repeat(12, 1fr)',
        13: 'repeat(13, minmax(0, 1fr))',
      },
      maxHeight: {
        112: '28rem',
        128: '32rem',
      },
      zIndex: {
        '-1': '-1',
      },
      keyframes: {
        hoverFirefly: {
          '0%': {
            transform: 'translate(0, 0)',
          },
          '12%': {
            transform: 'translate(4px, 2px)',
          },
          '24%': {
            transform: 'translate(-3px, 4px)',
          },
          '37%': {
            transform: ' translate(3px, -3px)',
          },
          '55%': {
            transform: 'translate(-2px, 0)',
          },
          '74%': {
            transform: 'translate(0, 3px)',
          },
          '88%': {
            transform: 'translate(-4px, -2px)',
          },
          '100%': {
            transform: 'translate(0, 0)',
          },
        },
      },
      animation: {
        'hover-firefly': 'hoverFirefly var(--speed) infinite',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
