import Kinet from 'kinet';

/**
 * Firefly that follows mouse
 *
 * @export
 * @class Firefly
 */
export default class Firefly {
  constructor() {
    this.kinet = new Kinet({
      acceleration: 0.02,
      friction: 0.25,
      names: ['x', 'y'],
    });

    this.firefly = document.getElementById('firefly');

    this._attachHandlers();
  }

  /**
   * Attach event handlers
   */
  _attachHandlers() {
    this.kinet.on('tick', this._tickEvent.bind(this));
    document.addEventListener('mousemove', this._mouseMoveEvent.bind(this));
  }

  _tickEvent(instances) {
    this.firefly.style.transform = `translate3d(${instances.x.current}px, ${instances.y.current}px, 0) rotateX(${instances.x.velocity / 2}deg) rotateY(${instances.y.velocity / 2}deg)`;
  }

  _mouseMoveEvent(e) {
    this.kinet.animate('x', e.pageX - window.innerWidth / 2);
    this.kinet.animate('y', e.pageY - window.innerHeight / 2);
  }
}
