import { debounce, pixelBreakpoints } from '../helpers';

const selectors = {
  expander: '[data-filter-expander]',
  gridWrapper: '[data-product-grid-wrapper]',
  filterDetails: '[data-filter-details]',
  filtersForm: '[data-filters-form]',
  filterDrawer: '[data-filter-drawer]',
  showMoreItem: '[data-show-more-item]',
  desktopFacets: '[data-desktop-facets]',
  mobileFacets: '[data-mobile-facets]',
  filterCount: '[data-filter-count]',
};

const classes = {
  mobileLoading: ['opacity-40', 'pointer-events-none'],
  desktopLoading: ['md:opacity-40', 'md:pointer-events-none'],
};

class FacetFiltersForm extends HTMLElement {
  constructor() {
    super();
    this.onActiveFilterClick = this.onActiveFilterClick.bind(this);

    this.debouncedOnSubmit = debounce(event => {
      this.onSubmitHandler(event);
    }, 500);

    const facetForm = this.querySelector('form');
    facetForm.addEventListener('input', e => {
      document
        .querySelector(selectors.filterDrawer)
        .classList.add(...classes.desktopLoading);
      document
        .querySelector(selectors.gridWrapper)
        .classList.add(...classes.mobileLoading);
      this.debouncedOnSubmit(e);
    });
  }

  static setListeners() {
    const onHistoryChange = event => {
      const searchParams = event.state
        ? event.state.searchParams
        : FacetFiltersForm.searchParamsInitial;
      if (searchParams === FacetFiltersForm.searchParamsPrev) {
        return;
      }
      FacetFiltersForm.renderPage(searchParams, null, false);
    };
    window.addEventListener('popstate', onHistoryChange);
  }

  static renderPage(searchParams, event, updateURLHash = true) {
    FacetFiltersForm.searchParamsPrev = searchParams;
    const sections = FacetFiltersForm.getSections();

    sections.forEach(section => {
      const url = `${window.location.pathname}?section_id=${section.section}&${searchParams}`;
      const filterDataUrl = element => element.url === url;
      if (FacetFiltersForm.filterData.some(filterDataUrl)) {
        FacetFiltersForm.renderSectionFromCache(filterDataUrl, event);
      } else {
        FacetFiltersForm.renderSectionFromFetch(url, event);
      }
    });

    if (updateURLHash) {
      FacetFiltersForm.updateURLHash(searchParams);
    }
  }

  static renderSectionFromFetch(url, event) {
    fetch(url)
      .then(response => response.text())
      .then(responseText => {
        const html = responseText;
        FacetFiltersForm.filterData = [
          ...FacetFiltersForm.filterData,
          { html, url },
        ];
        FacetFiltersForm.renderFilters(html, event);
        FacetFiltersForm.renderProductGridContainer(html);
      });
  }

  static renderSectionFromCache(filterDataUrl, event) {
    const html = FacetFiltersForm.filterData.find(filterDataUrl).html;
    FacetFiltersForm.renderFilters(html, event);
    FacetFiltersForm.renderProductGridContainer(html);
  }

  static renderProductGridContainer(html) {
    const parsedHtml = new DOMParser()
      .parseFromString(html, 'text/html')
      .querySelector(selectors.gridWrapper).innerHTML;

    document.querySelector(selectors.gridWrapper).innerHTML = parsedHtml;
    document
      .querySelector(selectors.gridWrapper)
      .classList.remove(...classes.mobileLoading);
  }

  static renderFilters(html, event) {
    const parsedHTML = new DOMParser().parseFromString(html, 'text/html');

    const facetDetailsElements = parsedHTML.querySelectorAll(
      selectors.filterDetails,
    );
    const matchesIndex = element => {
      const jsFilter = event
        ? event.target.closest(selectors.filterDetails)
        : undefined;
      return jsFilter
        ? element.dataset.index === jsFilter.dataset.index
        : false;
    };
    const facetsToRender = Array.from(facetDetailsElements).filter(
      element => !matchesIndex(element),
    );
    facetsToRender.forEach(element => {
      document.querySelector(
        `${selectors.filterDetails}[data-index="${element.dataset.index}"]`,
      ).innerHTML = element.innerHTML;
    });

    FacetFiltersForm.renderActiveFacets(parsedHTML);
  }

  static renderActiveFacets(html) {
    const activeFacetElementSelectors = [
      selectors.desktopFacets,
      selectors.mobileFacets,
    ];

    activeFacetElementSelectors.forEach(selector => {
      const activeFacetsElement = html.querySelector(selector);
      if (!activeFacetsElement) {
        return;
      }
      document.querySelector(selector).innerHTML =
        activeFacetsElement.innerHTML;
    });

    const filterCount = html.querySelector(selectors.filterCount);
    if (filterCount) {
      document.querySelector(selectors.filterCount).innerHTML =
        filterCount.innerHTML;
    }

    document
      .querySelector(selectors.filterDrawer)
      .classList.remove(...classes.desktopLoading);
  }

  static updateURLHash(searchParams) {
    history.pushState(
      { searchParams },
      '',
      `${window.location.pathname}${searchParams && '?'.concat(searchParams)}`,
    );
  }

  static getSections() {
    return [
      {
        section: document.getElementById('product-grid').dataset.id,
      },
    ];
  }

  createSearchParams(form) {
    const formData = new FormData(form);
    return new URLSearchParams(formData).toString();
  }

  onSubmitForm(searchParams, event) {
    FacetFiltersForm.renderPage(searchParams, event);
  }

  onSubmitHandler(event) {
    event.preventDefault();
    const formIds = ['facet-filters-form'];
    if (window.matchMedia(`(min-width: ${pixelBreakpoints.md})`).matches) {
      formIds.push('facet-sort-form');
    } else {
      formIds.push('facet-sort-form-mobile');
    }

    const sortFilterForms = document.querySelectorAll(
      'facet-filters-form form',
    );
    const forms = [];
    sortFilterForms.forEach(form => {
      if (formIds.includes(form.id)) {
        forms.push(this.createSearchParams(form));
      }
    });
    this.onSubmitForm(forms.join('&'), event);
  }

  onActiveFilterClick(event) {
    event.preventDefault();
    const url =
      event.currentTarget.href.indexOf('?') == -1
        ? ''
        : event.currentTarget.href.slice(
            event.currentTarget.href.indexOf('?') + 1,
          );
    FacetFiltersForm.renderPage(url);
  }
}

class PriceRange extends HTMLElement {
  constructor() {
    super();
    this.querySelectorAll('input').forEach(element =>
      element.addEventListener('change', this.onRangeChange.bind(this)),
    );
    this.setMinAndMaxValues();
  }

  onRangeChange(event) {
    this.adjustToValidValues(event.currentTarget);
    this.setMinAndMaxValues();
  }

  setMinAndMaxValues() {
    const inputs = this.querySelectorAll('input');
    const minInput = inputs[0];
    const maxInput = inputs[1];
    if (maxInput.value) {
      minInput.setAttribute('max', maxInput.value);
    }
    if (minInput.value) {
      maxInput.setAttribute('min', minInput.value);
    }
    if (minInput.value === '') {
      maxInput.setAttribute('min', 0);
    }
    if (maxInput.value === '') {
      minInput.setAttribute('max', maxInput.getAttribute('max'));
    }
  }

  adjustToValidValues(input) {
    const value = Number(input.value);
    const min = Number(input.getAttribute('min'));
    const max = Number(input.getAttribute('max'));

    if (value < min) {
      input.value = min;
    }
    if (value > max) {
      input.value = max;
    }
  }
}

class FacetRemove extends HTMLElement {
  constructor() {
    super();
    const facetLink = this.querySelector('a');
    facetLink.setAttribute('role', 'button');
    facetLink.addEventListener('click', this.closeFilter.bind(this));
  }

  closeFilter(event) {
    event.preventDefault();
    const form =
      this.closest('facet-filters-form') ||
      document.querySelector('facet-filters-form');
    form.onActiveFilterClick(event);
  }
}

class ShowMoreButton extends HTMLElement {
  constructor() {
    super();
    const button = this.querySelector('button');
    button.addEventListener('click', event => {
      this.expandShowMore(event);
    });
  }

  expandShowMore(event) {
    const parentDisplay = event.target.closest(selectors.filterDetails);
    this.querySelectorAll(selectors.expander).forEach(element =>
      element.classList.toggle('hidden'),
    );
    parentDisplay
      .querySelectorAll(selectors.showMoreItem)
      .forEach(item => item.classList.toggle('hidden'));
  }
}

const initFacets = () => {
  FacetFiltersForm.filterData = [];
  FacetFiltersForm.searchParamsInitial = window.location.search.slice(1);
  FacetFiltersForm.searchParamsPrev = window.location.search.slice(1);
  customElements.define('facet-filters-form', FacetFiltersForm);
  FacetFiltersForm.setListeners();

  customElements.define('price-range', PriceRange);
  customElements.define('facet-remove', FacetRemove);
  customElements.define('show-more-button', ShowMoreButton);
};

export default initFacets;
