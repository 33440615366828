import CartCrossSell from './CartCrossSell';
import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import FocusTrap from 'focus-trap-react';

import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionLength = 300;

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();

  let percentage = 0;

  const thresholdInCents = miniCartData.settings.freeShippingThreshold * 100;
  const difference = thresholdInCents - cart.total_price;

  if (difference > 0) {
    percentage = (cart.total_price * 100) / thresholdInCents;
  } else {
    percentage = 100;
  }

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 delay-${transitionLength}`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-grey-1 transition-opacity duration-${transitionLength}`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute bottom-0 right-0 top-0 z-50 flex w-11/12 flex-col bg-secondary-1 px-4 pb-11 transition-transform sm:w-full sm:max-w-md md:max-w-2xl duration-${transitionLength} md:px-15`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="pb-6 pt-4 text-center md:py-6">
            <div class="mb-2 flex items-center justify-between">
              {miniCartData?.settings?.freeShippingThreshold > 0 && (
                <div class="type-text-8">
                  {percentage === 100
                    ? getTranslation('mini_cart.freeShippingCompleteMessage')
                    : getTranslation('mini_cart.freeShippingMessage', {
                        amount: formatMoney(difference, theme.moneyFormat),
                      })}
                </div>
              )}

              <button onClick={() => toggleMiniCart(false)}>
                <svg
                  className="h-8 w-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {miniCartData?.settings?.freeShippingThreshold > 0 && (
              <div class="h-2 w-full rounded-full bg-grey-2">
                <div
                  class="h-2 rounded-full bg-primary-1"
                  style={{ width: `${percentage.toFixed(2)}%` }}
                ></div>
              </div>
            )}
          </div>

          <div
            class="flex flex-col-reverse overflow-auto"
            data-cart-items
            data-scroll-lock-scrollable
          >
            <CartCrossSell />

            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => <CartItem item={item} key={item.key} />)
            ) : (
              <CartEmpty />
            )}
          </div>

          <div class="mt-auto">
            {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}

            <div class="mt-8 flex items-center justify-end">
              <form action={`${Shopify.routes.root}cart`} method="POST">
                <button
                  type="submit"
                  name="checkout"
                  class="button button--primary"
                >
                  {getTranslation('mini_cart.checkout')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
