import Product from '../components/product/Product';

export default class MainProduct {
  constructor(section) {
    const product = section.el.querySelector('[data-product]');
    if (product) {
      new Product(product, {}, section.id);
    }
  }
}
