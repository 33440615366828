import ComplementaryProducts from './ComplementaryProducts';
import ProductForm from './ProductForm';
import ProductImages from './ProductImages';
import { formatMoney } from '@shopify/theme-currency';

const selectors = {
  priceWrapper: '[data-price-wrapper]',
  productPrice: '[data-product-price]',
  comparePrice: '[data-compare-price]',
  comparePriceText: '[data-compare-text]',
};

const cssClasses = {
  hidden: 'hidden',
};

/**
 * The wrapper class for PDP components
 *
 * @export
 * @class Product
 */
export default class Product {
  /**
   * Creates an instance of Product.
   *
   * @param {HTMLElement} el The container of this product
   * @param {Object} config Settings object
   * @param {String} config Section ID
   */
  constructor(el, config = {}, sectionId) {
    this.el = el;
    this.sectionId = sectionId;
    this.config = Object.assign(
      { isQuickShop: false, isFeaturedProduct: false },
      config,
    );

    this._initComponents();
  }

  /**
   * Init all components that make up the product
   */
  _initComponents() {
    this.productImages = new ProductImages(this.el);
    this.productForm = new ProductForm(this.el, {
      onVariantChange: variant => this._onVariantChange(variant),
      isQuickShop: this.config.isQuickShop,
      isFeaturedProduct: this.config.isFeaturedProduct,
    });
    new ComplementaryProducts(this.el, this.sectionId);
  }

  /**
   * Call functions to update on variant change from product form
   *
   * @param {Object} variant
   */
  _onVariantChange = variant => {
    this.productImages.change(variant);
    this._updatePrice(variant);
  };

  /**
   * Update prices when selected variant changes
   *
   * @param {Object} variant
   */
  _updatePrice = variant => {
    const comparePrice = this.el.querySelector(selectors.comparePrice);
    const compareText = this.el.querySelector(selectors.comparePriceText);
    const mainPrice = this.el.querySelector(selectors.productPrice);

    const isOnSale = variant.compare_at_price > variant.price;
    const comparePriceText = isOnSale
      ? formatMoney(variant.compare_at_price, theme.moneyFormat)
      : '';

    // Update the product price
    mainPrice.innerHTML = formatMoney(variant.price, theme.moneyFormat);

    // Update compare at price depending on whether variant is on sale
    if (comparePrice && compareText) {
      comparePrice.innerHTML = comparePriceText;
      if (isOnSale) {
        comparePrice.classList.remove(cssClasses.hidden);
        compareText.classList.remove(cssClasses.hidden);
      } else {
        comparePrice.classList.add(cssClasses.hidden);
        compareText.classList.add(cssClasses.hidden);
      }
    }
  };
}
