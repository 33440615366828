import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  testimonialVideo: '[data-testimonial-video]',
  testimonialPoster: '[data-testimonial-poster]',
  testimonialPlay: '[data-testimonial-play]',
};

export default class DynamicVideoTestimonials {
  constructor(section) {
    this.el = section.el;
    this.initializeCarousel();
    this.initVideos();
  }

  initializeCarousel() {
    initCarousel(this.el, {
      perPage: 1,
      perMove: 1,
      arrows: false,
      pagination: true,
      center: true,
      focus: 'center',
      breakpoints: {
        [unitlessBreakpoints.xs]: {
          fixedWidth: 400,
        },
        [unitlessBreakpoints.lg]: {
          pagination: false,
          destroy: true,
        },
      },
    });
  }

  initVideos() {
    const videos = this.el.querySelectorAll(selectors.testimonialVideo);
    if (!videos.length) {
      return;
    }

    videos.forEach(video => {
      const videoEl = video.querySelector('video');
      const poster = video.querySelector(selectors.testimonialPoster);
      const play = poster.querySelector(selectors.testimonialPlay);

      play.addEventListener('click', () => {
        poster.classList.add('hidden');
        videoEl.play();
      });

      videoEl.addEventListener('ended', () => {
        poster.classList.remove('hidden');
      });
    });
  }
}
