import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartTotals = () => {
  const {
    cart: { cart_level_discount_applications, total_price },
  } = useCartContext();

  return (
    <div>
      <div class="type-text-5 mt-5 flex justify-between md:mt-6">
        <p>{getTranslation('cart.subtotal')}</p>
        <p>{formatMoney(total_price, theme.moneyFormat)}</p>
      </div>

      <div class="type-text-5 mt-2 flex items-center justify-between">
        <p>{getTranslation('mini_cart.shipping')}</p>
        <p class="type-text-7">
          {getTranslation('mini_cart.shipping_at_checkout')}
        </p>
      </div>

      {/* Discounts */}
      <div class="mt-4 flex flex-col items-end space-y-2">
        {cart_level_discount_applications.length > 0 &&
          cart_level_discount_applications.map(item => (
            <div class="type-text-7">
              {item.title} (-
              {formatMoney(item.total_allocated_amount, theme.moneyFormat)})
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartTotals;
