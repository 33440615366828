import CartCrossSellProduct from './CartCrossSellProduct';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const CartCrossSell = () => {
  const { cart, isMiniCartOpen } = useCartContext();
  const [cartIds, setCartIds] = useState(null);
  const [crossSell, setCrossSell] = useState(null);
  const [crossSellItems, setCrossSellItems] = useState(null);

  useEffect(() => {
    setCartIds(cart.items.map(item => item.product_id));
  }, [cart.items]);

  useEffect(() => {
    if (isMiniCartOpen && crossSellItems && cart && cart.items.length > 0) {
      setCrossSell(null);
      cart.items
        .slice()
        .reverse()
        .forEach(item => {
          if (
            crossSellItems[item.product_id] &&
            !cartIds.includes(crossSellItems[item.product_id]?.id)
          ) {
            setCrossSell(crossSellItems[item.product_id]);
          }
        });
    }
  }, [cartIds, crossSellItems, cart.items, isMiniCartOpen]);

  useEffect(() => {
    if (isMiniCartOpen && cart && cart.items.length > 0) {
      setCrossSellItems(null);
      cart.items.forEach(item => {
        fetch(
          `${Shopify.routes.root}recommendations/products.json?product_id=${item.product_id}&limit=1&intent=complementary`,
        )
          .then(response => {
            return response.json();
          })
          .then(({ products }) => {
            if (products.length > 0) {
              setCrossSellItems(prev => ({
                ...prev,
                [item.product_id]: products[0],
              }));
            }
          });
      });
    }
  }, [cart.items, isMiniCartOpen]);

  return crossSell ? (
    <div class="relative py-8">
      <div>
        <CartCrossSellProduct key={crossSell.product_id} product={crossSell} />
      </div>
    </div>
  ) : null;
};

export default CartCrossSell;
