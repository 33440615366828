const selectors = {
  storyVideo: '[data-story-video]',
  storyPoster: '[data-story-poster]',
  storyPlay: '[data-story-play]',
};

export default class DynamicStory {
  constructor(section) {
    this.el = section.el;
    this.init();
  }

  init() {
    const videos = this.el.querySelectorAll(selectors.storyVideo);
    if (!videos.length) {
      return;
    }

    videos.forEach(video => {
      const videoEl = video.querySelector('video');
      const poster = video.querySelector(selectors.storyPoster);
      const play = poster.querySelector(selectors.storyPlay);

      play.addEventListener('click', () => {
        poster.classList.add('hidden');
        videoEl.play();
      });

      videoEl.addEventListener('ended', () => {
        poster.classList.remove('hidden');
      });
    });
  }
}
