const selectors = {
  heroVideo: '[data-hero-video] video',
  heroPlay: '[data-hero-play]',
  heroPause: '[data-hero-pause]',
  heroAudio: '[data-hero-audio]',
  heroAudioPlay: '[data-audio-play]',
  heroAudioPause: '[data-audio-pause]',
};

export default class DynamicHero {
  constructor(section) {
    this.el = section.el;
    this.init();
  }

  init() {
    this.initVideo();
    this.initAudio();
  }

  initVideo() {
    const video = this.el.querySelector(selectors.heroVideo);
    if (!video) {
      return;
    }

    const play = this.el.querySelector(selectors.heroPlay);
    const pause = this.el.querySelector(selectors.heroPause);

    play.addEventListener('click', () => {
      video.play();
      play.classList.add('hidden');
      pause.classList.remove('hidden');
    });

    pause.addEventListener('click', () => {
      video.pause();
      pause.classList.add('hidden');
      play.classList.remove('hidden');
    });
  }

  initAudio() {
    const audio = this.el.querySelector(selectors.heroAudio);
    if (!audio) {
      return;
    }

    const play = this.el.querySelector(selectors.heroAudioPlay);
    const pause = this.el.querySelector(selectors.heroAudioPause);

    play.addEventListener('click', () => {
      audio.play();
      play.classList.add('hidden');
      pause.classList.remove('hidden');
    });

    pause.addEventListener('click', () => {
      audio.pause();
      pause.classList.add('hidden');
      play.classList.remove('hidden');
    });
  }
}
