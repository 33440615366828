import * as focusTrap from 'focus-trap';

const selectors = {
  openFilters: '[data-filter-drawer-toggle]',
  closeFilters: '[data-filter-drawer-close]',
  drawer: '[data-filter-drawer]',
  filterForm: '[data-filter-form]',
};

export default class MobileFilterDrawer {
  constructor() {
    this.el = document.querySelector(selectors.filterForm);
    this.drawer = this.el.querySelector(selectors.drawer);
    this.bodyEl = document.querySelector('.template-collection');

    this._init();

    this.focusTrap = focusTrap.createFocusTrap(selectors.drawer, {
      onActivate: () => {
        this.el.classList.add('focus-trap');
      },
      onDeactivate: () => {
        this.el.classList.remove('focus-trap');
      },
      initialFocus: () => document.querySelector(selectors.drawer),
      clickOutsideDeactivates: false,
    });
  }

  _init = () => {
    const openFilters = this.el.querySelector(selectors.openFilters);

    if (openFilters) {
      openFilters.addEventListener('click', () => {
        this._openDrawer();
      });
    }

    const closeFilters = this.el.querySelectorAll(selectors.closeFilters);

    if (closeFilters) {
      closeFilters.forEach(filter => {
        filter.addEventListener('click', () => {
          this._closeDrawer();
        });
      });
    }
  };

  _openDrawer = () => {
    this.drawer.classList.remove('hidden');
    this.bodyEl.classList.add('filter-drawer-open');
    this.focusTrap.activate();
  };

  _closeDrawer = () => {
    this.drawer.classList.add('hidden');
    this.bodyEl.classList.remove('filter-drawer-open');
    this.focusTrap.deactivate();
  };
}
