import AsyncView from '../../helpers/AsyncView';
import Modal from '../Modal';
import Product from './Product';

/**
 * Container class for quickshop.
 * Exported as singleton instance for ease of use across components.
 *
 * @class QuickShop
 */
class QuickShop {
  constructor() {
    this.quickshopContainer = document.getElementById('ajax-modal-content');
    this.modalContainer = document.querySelector('[data-pxu-modal]');
  }

  /**
   * Set up a modal instance
   */
  _initModal() {
    this.modal = new Modal({
      onOpen: this._onModalOpen,
    });
  }

  _onModalOpen = () => {
    const product = this.modalContainer.querySelector('[data-product]');

    // Create a new Product instance for the requested quickshop product
    new Product(product, {
      isQuickShop: true,
    });
  };

  /**
   * Open a quickshop
   *
   * @param {string} url The product url
   * @param {string} hash The product json hash to compare to what may be in storage
   */
  async open(url, hash) {
    const { html } = await AsyncView.load(url, { view: 'quickshop' }, { hash });

    // Inject product to temporary container
    this.quickshopContainer.innerHTML = html.content;

    this._initModal();

    this.modal.open('#ajax-modal-content');
  }

  /**
   * Close a quickshop
   */
  close() {
    this.modal.close();
  }
}

export default new QuickShop();
