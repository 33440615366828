const selectors = {
  complementaryProducts: '[data-complementary-products]',
};

const rootMargin = '0px 0px 200px 0px';
const limit = 4;

/**
 * Complementary Products block
 *
 * @export
 * @class ComplementaryProducts
 */
export default class ComplementaryProducts {
  constructor(el, sectionId) {
    this.sectionId = sectionId;
    this.complementaryProducts = el.querySelector(
      selectors.complementaryProducts,
    );

    if (this.complementaryProducts) {
      this.productId = this.complementaryProducts.dataset.productId;
      // TODO: create lazy data pattern as helper
      const observer = new IntersectionObserver(
        this._fetchComplementaryProducts,
        {
          rootMargin,
        },
      );

      observer.observe(this.complementaryProducts);
    }
  }

  _fetchComplementaryProducts = (entries, observer) => {
    if (!entries[0].isIntersecting) {
      return;
    }

    observer.unobserve(this.complementaryProducts);

    const url = `${theme.routes.recommendations}?section_id=${this.sectionId}&limit=${limit}&product_id=${this.productId}&intent=complementary`;

    fetch(url)
      .then(response => response.text())
      .then(text => {
        const html = document.createElement('div');
        html.innerHTML = text;
        const complementary = html.querySelector(
          selectors.complementaryProducts,
        );

        if (complementary && complementary.innerHTML.trim().length) {
          this.complementaryProducts.innerHTML = complementary.innerHTML;
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };
}
