import Scroll from '../helpers/scroll';
import Search from '../components/search/Search';
import scrollLock from 'scroll-lock';

const selectors = {
  headerNavigation: '[data-header-navigation]',
  headerNavDetails: '[data-header-nav-details]',
  navHamburger: '[data-nav-hamburger]',
  navClose: '[data-nav-close]',
  announcementBar: '[data-announcement-bar]',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;

    const { settings } = section.data;

    if (settings.enable_fixed_header) {
      this.listener = new Scroll(this.onScroll, this.section.el);
      this.section.el.className +=
        ' sticky top-0 z-50 bg-white transition-transform';
    }

    new Search(section);

    this.initNavigation();
    this.initMobileNavigation();
  }

  onScroll = direction => {
    if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
    }
  };

  showMenu = () => {
    this.section.el.classList.remove('-translate-y-full');
  };

  hideMenu = () => {
    this.section.el.classList.add('-translate-y-full');
  };

  initNavigation = () => {
    const linkDetails = this.section.el.querySelectorAll(
      selectors.headerNavDetails,
    );
    document.addEventListener('click', e => {
      linkDetails.forEach(link => {
        if (e.target !== link && !link.contains(e.target)) {
          link.removeAttribute('open');
        }
      });
    });
  };

  initMobileNavigation = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.headerNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navHamburger.addEventListener('click', () => {
      const announcementBar = document.querySelector(selectors.announcementBar);
      const headerHeight = this.section.el.offsetHeight;
      let announcementBarHeight = 0;
      if (announcementBar) {
        const announcementElementHeight = announcementBar.offsetHeight;
        if (document.documentElement.scrollTop < announcementElementHeight) {
          announcementBarHeight = announcementBar.offsetHeight;
        }
      }
      const totalHeaderHeight = headerHeight + announcementBarHeight;
      headerNavigation.style.top = `${totalHeaderHeight - 1}px`;
      navHamburger.classList.add('hidden');
      navClose.classList.remove('hidden');
      headerNavigation.classList.remove('hidden');
      headerNavigation.classList.add('flex');
      scrollLock.disablePageScroll();
    });
    navClose.addEventListener('click', () => {
      navHamburger.classList.remove('hidden');
      navClose.classList.add('hidden');
      headerNavigation.classList.add('hidden');
      headerNavigation.classList.remove('flex');
      scrollLock.enablePageScroll();
    });
  };
}
